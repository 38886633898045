<template>
  <div>
    <NavBar
      style="max-height=50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
    ></NavBar>

    <v-app>
      <div style="background-color: #f5faff; height: 92vh !important;">
         <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10">
            <h3 style="text-align:left; margin-left:65px; margin-top:30px; font-weight:800; font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;">Add Specialization</h3>
            <v-card style="width:90%; margin-left:65px; height:100%; margin-top:20px;	height: 70vh; overflow:scroll; overflow-x: hidden;">
              <div style="display: flex; flex-direction: row; margin:10px" align="left">
                <div
                style="width: 100%; display: flex; flex-direction: column"
                align="left">

                <div style="display: flex; flex-direction: row; margin-top:5%">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> Specialization Name</p></div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                      label="Enter name in English"
                      solo
                      v-model="spec_name"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> Specialization Name (Burmese)</p></div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                      label="Enter name in Burmese"
                      solo
                      v-model="spec_name_mm"
                    ></v-text-field>
                  </div>
                </div>


                <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> Specialization Description</p></div>
                  <div style="width: 50%" align="right">
                    <v-textarea
                      label="Enter description in English"
                      solo
                      v-model="spec_desc"
                    ></v-textarea>
                  </div>
                </div>

                 <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> Specialization Description (Burmese)</p></div>
                  <div style="width: 50%" align="right">
                    <v-textarea
                      label="Enter description in Burmese"
                      solo
                      v-model="spec_desc_mm"
                    ></v-textarea>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> Specialization Position</p></div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                      label="Enter Position"
                      solo
                      v-model="spec_position"
                      type="number"
                    ></v-text-field>
                  </div>
                </div>

                <div align="center">
                  <v-btn
                    @click="createSpecialization"
                    style=" width:50%; height: 40px; margin-right:10%; margin-top:20px;"
                    color="primary"
                    :disabled="isaddSpecialisationClicked || !(spec_position && parseInt(spec_position) >= 0 && spec_name.trim()
                    !='' && spec_name_mm.trim() !='' && spec_desc.trim() !='' && spec_desc_mm.trim() != '')"
                    >Create Specialization</v-btn>
                </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../navbar.vue";
import SideBar from "../../../views/sideBar.vue";

export default {
  name: "CustomerHome",
  data() {
    return {
      currentUserName: "",
      currentUser: "",
      user_id: "",
      spec_name: "",
      spec_desc: "",
      spec_name_mm : "",
      spec_desc_mm : "",
      pageName: "createSpecialization",
      isaddSpecialisationClicked : false,
      spec_position: null
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "DoctorLogin",
      });
    } else {
      var getAccessData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        adminType: "ADMIN",
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/getAdmin", getAccessData)
        .then((getAdminResponse) => {
          this.currentUserName = getAdminResponse.data.data.admin_name;
          this.user_id = getAdminResponse.data.data._id;
        })
        .catch((getAdminError) => {
          if (getAdminError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          }
          else if(getAdminError.response.status == 400)
          {
            window.alert(getAdminError);
          }
        });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
    createSpecialization() {
      this.isaddSpecialisationClicked = true
      var CreatespecBody = {
        specializationName: this.spec_name.trim(),
        description: this.spec_desc.trim(),
        specializationName_mm : this.spec_name_mm.trim(),
        description_mm : this.spec_desc_mm.trim(),
        token: this.currentUser,
        position: this.spec_position,
        typeOfUser: "ADMIN",
      };
      if (this.spec_name.trim() != "" && this.spec_desc.trim() != "" && this.spec_name_mm.trim() !="" && this.spec_desc_mm.trim() !="") {
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/createSpecialization",
            CreatespecBody
          )
          .then((createDoctorResponse) => {
            alert("Specialization created!!");
            this.$router.push({
              name: 'ViewSpecialization'
            });
          })
          .catch((createDoctorError) => {
            this.isaddSpecialisationClicked = false;
            if (createDoctorError.response.status == 401) {
              window.alert("Sorry !! You are unauthorized !!");
              this.$router.push({
                name: "AdminLogin",
              });
            }

            if (createDoctorError.response.status == 305) {
              window.alert("Specialization already exists");
            }
          });
      } else {
        alert("Please provide all the details");
      }
    },
  },
};
</script>

  <style>
td,
th {
  padding: 10px;
}
#facebook{
  display: none;
}
</style>